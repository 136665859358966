<template>
  <div>

    <app-step step="2" />

    <div class="container pt-4">
      <div class="row px-3">
        <div class="col-sm-3 border border-bottom-0">
          <div class="row text-primary d-flex justify-content-center">
            <div class="col-2 my-auto py-2"><h4 class="my-0"><i class="fad fa-gift-card"></i></h4></div>
            <div class="col-8 my-auto py-2"><h5 class="my-0">Canjear código</h5></div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">

      <hr class="mt-0">
      
      <div class="row d-flex justify-content-between">
        
        <b-form class="col-12 px-3">
          <b-form-input
            id="inline-form-input-name"
            class="mb-2 mr-sm-2 mb-sm-0"
            placeholder="Ingresa el código de regalo"
          ></b-form-input>
        </b-form>

      </div>

      <div class="row mt-5">
        <div class="col-4 ml-auto">
          <b-button to="/confirm" variant="primary" block>Siguiente</b-button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Order',
  data() {
    return {
      order: '' 
    }
  },
  components: {
    AppStep: () => import('@/components/AppStep.vue')
  },
  created() {
    
  },
}
</script>